<template>
	<div class="notfind-page">
		<img src="../../static/images/404.png" />
		<el-button type="primary" round @click="gotoIndex">返回首页</el-button>
	</div>
</template>
<script>
export default {
	methods: {
		gotoIndex() {
            location.href = '/';
		},
	},
};
</script>

<style lang="scss" scoped>
.notfind-page {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	> img {
		width: 460px;
		height: 460px;
		margin-bottom: 50px;
	}
}
</style>
